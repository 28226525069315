/* eslint-disable max-lines-per-function */
import { useAegModeling } from '@hooks';
import React, { SetStateAction, useEffect, useState } from 'react';
import { FormStylingMode, GenericModal } from 'src/components/shared';
import { Button, Grid } from '@mui/material';
import { DataValidationSnapshot } from '@gql/types/graphql';
import { getBookingSupportUrl } from '@utils/stringHelpers';
import { calculateVariance, formatAbsoluteNumber } from '@utils/numberHelpers';
import {
  GridContainer,
  GridItemTitle,
  QuestionsText,
  ButtonContainer,
  ConfirmationModalContainer,
} from './ReviewUpdatesModal.styled';

export const ReviewUpdatesModal = ({
  previousDataValidationSnapshot,
  setOpen,
  onClose,
}: {
  previousDataValidationSnapshot?: DataValidationSnapshot
  setOpen: React.Dispatch<SetStateAction<boolean>>,
  onClose: () => void
}) => {
  const [
    currentDataValidationSnapshot,
    setCurrentDataValidationSnapshot,
  ] = useState<DataValidationSnapshot | null>(null);
  const { getValidationFields } = useAegModeling();

  useEffect(() => {
    const loadValidationFields = async () => {
      const fields = await getValidationFields();
      setCurrentDataValidationSnapshot(fields);
    };
    void loadValidationFields();
  }, []);

  const rows = [
    [
      'Number of Shows',
      previousDataValidationSnapshot?.numberOfShows,
      currentDataValidationSnapshot?.numberOfShows,
    ],
    [
      'Gross Ticket Sales',
      previousDataValidationSnapshot?.grossTicketSales,
      currentDataValidationSnapshot?.grossTicketSales,
    ],
    [
      'Show Costs',
      previousDataValidationSnapshot?.lessShowCosts,
      currentDataValidationSnapshot?.lessShowCosts,
    ],
    [
      'Total Pool Income',
      previousDataValidationSnapshot?.netPoolIncomeBeforeArtistSplit,
      currentDataValidationSnapshot?.netPoolIncomeBeforeArtistSplit,
    ],
    [
      'Total Artist Earnings',
      previousDataValidationSnapshot?.totalArtistEarnings,
      currentDataValidationSnapshot?.totalArtistEarnings,
    ],
    [
      'Net Promoter Income',
      previousDataValidationSnapshot?.netPromoterIncome,
      currentDataValidationSnapshot?.netPromoterIncome,
    ],
  ];

  return (
    <GenericModal open mode={FormStylingMode.UNSET} title="Please review these values:">
      <ConfirmationModalContainer>
        <GridContainer data-testid="review-updates-grid">
          <Grid container columnSpacing={2} rowSpacing={1} columns={8}>
            <GridItemTitle item xs={2}>
              Value
            </GridItemTitle>
            <GridItemTitle item xs={2}>
              Before Update
            </GridItemTitle>
            <GridItemTitle item xs={2}>
              After Update
            </GridItemTitle>
            <GridItemTitle item xs={1}>
              Variance
            </GridItemTitle>
            {rows.map((columns, rowIndex) => (
              <React.Fragment key={`review-updates-row-${rowIndex}`}>
                {columns.map((value, index) => (
                  <Grid item xs={2} key={`review-updates-column-${rowIndex}-${index}`}>
                    {index === 0 ? value : (formatAbsoluteNumber(value as number ?? 0))}
                  </Grid>
                ))}
                <Grid
                  item
                  xs={2}
                  data-testid={`review-updates-column-${rowIndex}-3`}
                  key={`review-updates-column-${rowIndex}-3`}
                >
                  {formatAbsoluteNumber(
                    calculateVariance(
                      (columns[1] as number ?? 0), // previous value
                      (columns[2] as number ?? 0), // new value
                    ),
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </GridContainer>
        <ButtonContainer>
          <Button
            data-testid="update-workbook-button"
            variant="contained"
            onClick={() => {
              onClose?.();
              setOpen(false);
            }}
          >
            Continue
          </Button>
          <QuestionsText data-testid="questions-text">
            Questions? <a href={getBookingSupportUrl('Support with updating the workbook')}>Contact Booking Support</a>
          </QuestionsText>
        </ButtonContainer>
      </ConfirmationModalContainer>
    </GenericModal>
  );
};
