import { NosSettlementEmailQuery } from '@gql/types/graphql';
import { formatNumberToCurrency } from '@utils/numberHelpers';
import { NOSSummaryItem } from './NOSSummaryTable/NOSSummaryTable.models';

export const nosSummaryTableData = (NOSEmailData: NosSettlementEmailQuery): NOSSummaryItem[] => {
  if (!NOSEmailData) {
    return [];
  }
  const { expense, nosSettlement } = NOSEmailData;

  if (!expense || !expense.phases || !nosSettlement) {
    return [];
  }

  const expensePhase = expense.phases[0];
  const ancillaryItems = expensePhase?.ancillaryCategoryItems && expensePhase.ancillaryCategoryItems[0];
  const ancillaryTotal = ancillaryItems?.totalToBase;

  const { artistSettlement, internalSettlement } = nosSettlement;

  const currencyCode = NOSEmailData.nosSettlement?.event?.baseCurrency || 'USD';

  return [
    {
      name: 'Currency',
      value: currencyCode,
    },
    {
      name: 'Paid',
      value: artistSettlement?.actualBoxOfficeOverview?.totalSellableCapacity?.toLocaleString() || '-',
    },
    {
      name: 'Gross',
      value: formatNumberToCurrency(artistSettlement?.actualBoxOfficeOverview?.totalGrossToBase, { currencyCode }),
    },
    {
      name: 'Net Gross',
      value: formatNumberToCurrency(artistSettlement?.netShowReceipts?.netGrossReceiptsTotalToBase, { currencyCode }),
    },
    {
      name: 'Contribution to Pool',
      value: formatNumberToCurrency(artistSettlement?.netShowReceipts?.netShowReceiptsTotalToBase, { currencyCode }),
    },
    {
      name: 'Net to Artist',
      value: formatNumberToCurrency(
        artistSettlement?.artistSettlement?.artistNosSettlementTotalToBase,
        { currencyCode },
      ),
    },
    {
      name: 'Total Ancillaries',
      value: formatNumberToCurrency(ancillaryTotal, { currencyCode }),
    },
    {
      name: 'Net to AEG',
      value: formatNumberToCurrency(
        internalSettlement?.aegSettlement?.netProfitOrLossToAegTotalToBase,
        { currencyCode },
      ),
    },
    {
      name: 'Projected Net to Pool (No Premium)',
      value: formatNumberToCurrency(internalSettlement?.netToPool?.projected, { currencyCode }),
    },
    {
      name: 'Net to Pool',
      value: formatNumberToCurrency(internalSettlement?.netToPool?.actual, { currencyCode }),
    },
    {
      name: 'Variance',
      value: formatNumberToCurrency(internalSettlement?.netToPool?.variance, { currencyCode }),
    },
  ];
};
