import { DropdownOption } from '@components';
import { OfferStatus } from '@types';

export const offerStatusOptions: DropdownOption<OfferStatus>[] = [
  { value: OfferStatus.Draft, label: OfferStatus.Draft },
  {
    value: OfferStatus.PendingApproval,
    label: OfferStatus.PendingApproval,
  },
  {
    value: OfferStatus.Approved,
    label: OfferStatus.Approved,
  },
  {
    value: OfferStatus.SentToAgent,
    label: OfferStatus.SentToAgent,
  },
  {
    value: OfferStatus.OfferAccepted,
    label: OfferStatus.OfferAccepted,
  },
  {
    value: OfferStatus.RoutingConfirmed,
    label: OfferStatus.RoutingConfirmed,
  },
  {
    value: OfferStatus.Confirmed,
    label: OfferStatus.Confirmed,
  },
  { value: OfferStatus.Dead, label: OfferStatus.Dead },
];

export const SnackBarMessages = {
  savePreviousWorkbookSuccess: <>
    <span>Success!</span><br/>
    <span>Previous offer version saved</span>
  </>,
  savePreviousWorkbookError: <>
    <span>Something went wrong...</span><br/>
    <span>We were unable to save previous offer version. Please try again.</span>
  </>,
  saveWorkbookCopySuccess: <>
    <span>Success!</span><br/>
    <span>Workbook copy saved</span>
  </>,
  saveWorkbookCopyError: <>
    <span>Something went wrong...</span><br/>
    <span>We were unable to save workbook copy. Please try again.</span>
  </>,
};
