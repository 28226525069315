import {
  Box, CustomTheme, ListItem, styled,
} from '@mui/material';

export const ConfirmationModalContainer = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    padding: theme?.spacing(1),
  }),
);

export const ListContainer = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    listStyleType: 'disc',
  }),
);

export const ListItemStyled = styled(ListItem)(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'list-item',
    fontSize: theme?.spacing(1.75),
    '& a': {
      textDecoration: 'underline',
    },
  }),
);

export const AlertContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    padding: theme?.spacing(2),
  }),
);

export const ButtonContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'flex',
    gap: theme?.spacing(2),
    paddingLeft: theme?.spacing(2),
    marginTop: theme?.spacing(1),
    marginBottom: theme?.spacing(1),
  }),
);
